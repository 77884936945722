import Form from "../../components/form/form";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import './contact.scss'
import ContactBackground from '../../images/contact.JPG'

const Contact = () => {
  return(
    <div className="background" style={{backgroundImage: `linear-gradient(rgba(223,218,215,0.8), rgba(223,218,215,0.8)), url(${ContactBackground})`}}>
      <div className="background-container">
    <Header />
      <Form />
    <Footer />
    </div>
    </div>
  )
}

export default Contact;