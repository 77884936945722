import { useState, useEffect } from 'react';
import './footer.scss'
import myOffice from "../../images/myOffice.jpg"
import ABP from '../../images/ABP_logo.png'
import business_hour from '../../icons/business_hour.svg'
import Contact from '../../atoms/contact/contact';
import Address from '../../atoms/address/address';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  let navigate = useNavigate();

  const toTerms = () => {
    navigate('/terms');
  }

  const toPrivacy = () => {
    navigate('/privacy');
  }

  useEffect(() => {
    const handleScreenSize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', handleScreenSize);

    return () => {
      window.removeEventListener('resize', handleScreenSize)
    }
  },[])

  const changeDisplay = () => {
    if(screenSize > 760 && screenSize < 1200){
      return (
        <>
        <div className='details-map'>
      <div className='details-flex'>
        <div className=''>
          <div className='business-details'>
            <div className='flex'>
              <img 
                className='icon' 
                src={business_hour} 
                alt='business hour' 
              />
              <p>Business Hours</p>
            </div>
            <span>By Appointment</span>
          </div>
          <Contact />
          <Address />
        </div>
      </div>
      <div className='google-map'>
          <a href='https://www.google.com/maps/place/Regus+-+Adelaide+Victoria+Park/@-34.9347795,138.6217689,17z/data=!3m1!4b1!4m6!3m5!1s0x6ab0ceb0d3d0e9fd:0x6877429d9d176c8!8m2!3d-34.9347839!4d138.6243438!16s%2Fg%2F1hc1l_w7c?entry=ttu' target='_target'>
            <img id='map-image' src={myOffice} alt='my office location'/>
          <p>View on Google Maps</p>
        </a>
      </div>
    </div>
    <div className='flex terms-pages'>
        <p onClick={toTerms}>Terms and Conditions</p>
        <p onClick={toPrivacy}>Privacy Policy</p>
      </div>
    </>
      )
    } else if (screenSize > 1200){
      return(
        <>
        <div className='details-flex'>
          <div className=''>
            <div className='business-details'>
              <div className='flex'>
                <img 
                  className='icon' 
                  src={business_hour} 
                  alt='business hour' 
                />
                <p>Business Hours</p>
              </div>
              <span>By Appointment</span>
            </div>
            <Contact />
            <Address />
            <div className='flex terms-pages'>
          <p onClick={toTerms}>Terms and Conditions</p>
          <p onClick={toPrivacy}>Privacy Policy</p>
        </div>
          </div>
        </div>
        <div className='google-map'>
            <a href='https://www.google.com/maps/place/Regus+-+Adelaide+Victoria+Park/@-34.9347795,138.6217689,17z/data=!3m1!4b1!4m6!3m5!1s0x6ab0ceb0d3d0e9fd:0x6877429d9d176c8!8m2!3d-34.9347839!4d138.6243438!16s%2Fg%2F1hc1l_w7c?entry=ttu' target='_target'>
              <img id='map-image' src={myOffice} alt='my office location'/>
            <p>View on Google Maps</p>
          </a>
        </div>
      
      </>
        )
    } else {
      return(
      <>
      <div className='details-flex'>
        <div className=''>
          <div className='business-details'>
            <div className='flex'>
              <img 
                className='icon' 
                src={business_hour} 
                alt='business hour' 
              />
              <p>Business Hours</p>
            </div>
            <span>By Appointment</span>
          </div>
          <Contact />
          <Address />
        </div>
      </div>
      <div className='google-map'>
          <a href='https://www.google.com/maps/place/Regus+-+Adelaide+Victoria+Park/@-34.9347795,138.6217689,17z/data=!3m1!4b1!4m6!3m5!1s0x6ab0ceb0d3d0e9fd:0x6877429d9d176c8!8m2!3d-34.9347839!4d138.6243438!16s%2Fg%2F1hc1l_w7c?entry=ttu' target='_target'>
            <img id='map-image' src={myOffice} alt='my office location'/>
          <p>View on Google Maps</p>
        </a>
      </div>
      <div className='flex terms-pages'>
        <p onClick={toTerms}>Terms and Conditions</p>
        <p onClick={toPrivacy}>Privacy Policy</p>
      </div>
    </>
      )
    }
    
  }

  return(
    <div className="footer-wrapper">
      <div className='footers'>
        <div className='footer'>
          <a href='/'>
            <img src={ABP} alt="logo" className="footer-logo"/>
          </a> 
          <h1 className='footer-title'>
            Alison Budden Psychology
          </h1>
        </div>
        <h3 className='footer-subtitle'>“I live on the lands of the Kaurna people. In the spirit of reconciliation, I acknowledge the Traditional Custodians of country throughout Australia and their connections to land, waters and communities. I pay respect to their Elders past and present and extend that respect to all Aboriginal and Torres Strait Islander peoples today.”</h3>
      </div>
      {changeDisplay()}
    </div>
  )
}

export default Footer;