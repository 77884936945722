import phone from '../../icons/phone.svg'
import email from '../../icons/email.svg'

const Contact = () => {
  return(
    <>
      <div className='flex business-details'>
            <img 
              src={phone}
              className='icon'
              alt='phone'
            />
          <span>+61 459 248 358</span>
          </div>
          <div className='flex business-details'>
            <img 
            src={email}
              className='icon'
              alt='email'
            />
          <span>alison@alisonbuddenpsych.com</span>
          </div>
    </>
  )
}

export default Contact;