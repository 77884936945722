import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import './main.scss'
import SlideShow from "../../components/slide_show/slideShow";
import Message from "../../components/message/message";
import ApproachData from "../../database/approach.json";
import ServicesData from "../../database/services";
import MainBackground from '../../images/main.JPG'
// import { useEffect } from 'react';

const Main = () => {
  // useEffect(() => {
  //   fetch('http://localhost:3001/api/data').then(res => {
  //     if(!res.ok) {
  //       throw Error(`HTTP Error: ${res.status}`);
  //     }
  //     console.log("HERE")
  //     return res.json();
  //   })
  //   .then(data => {
  //     console.log("data", data);
  //   })
  //   .catch(err => {
  //     console.error("Error Message: ", err)
  //   })
  // }, [])
  return(
    <div className="background" style={{backgroundImage: `linear-gradient(rgba(223,218,215,0.85), rgba(223,218,215,0.85)), url(${MainBackground})`}}>
      <div className="background-container">
      <Header />
      <SlideShow />
      <Message title="Approach" data={ApproachData} />
      <Message title="Services" data={ServicesData} />
      <Footer />
      </div>
    </div>
  )
}

export default Main;