import "./service.scss";
import test from "../../images/Alison.JPG"

const Service = ({ data }) => {
  return (
    <>
      {data.map((d, i) => (
        <div key={i} className="service-wrapper">
          <h3>{d.title}</h3>
          <img src={d.img} alt={`${d.title}-image`} />
          {d.content.map((c, i) => (
            <ul>
              <li key={i}>{c}</li>
            </ul>
          ))}
        </div>
      ))}
    </>
  )
}

export default Service;