import Header from '../../components/header/header';

const Terms = () => {
  return (
    <>
      <Header />
      <div className='terms-container'>
        <h2>WEBSITE TERMS AND CONDITIONS OF USE</h2>
        <p>Welcome to the website of Alison Budden Psychology (ABP). By using this website or any of its contents, you agree to comply with and be bound by these terms and conditions. From time-to-time ABP may change these terms and conditions at its discretion and without notice, and by continuing your use you accept the revised terms and conditions.</p>
        <p>By using this website, you signify your acceptance of these terms and conditions of use. For the purposes of these terms and conditions, “Us”, “Our” and “We” refers to <a href='https://alisonbuddenpsych.com/' target='_target'>www.alisonbuddenpsych.com</a> (ABP) and “You” and “Your” refers to you, the client, visitor, website user or person using our website.</p>
        <p>This website does not use cookies and does not collect or store data or information.
        </p>
        <p>You can access the ABP website anonymously, without disclosing your personal information. The personal information that may be collected via this website includes any messages or comments you submit to ABP via this website or to an email address displayed on this website, which may include personal information such as your name, email address, telephone number and opinions.
        </p>
        <p>The Power Diary online booking system is a third-party website that is fully compliant with the requirements for health service data collection and protection in Australia. </p>
        <p>ABP does not sell, trade, or otherwise transfer to outside parties your personally identifiable information.</p>
        <p>ABP may use or disclose personal information that we collect about you via our website for the following:
        </p>
        <ul>
          <li>any purpose disclosed on our website for the collection of the information.</li>
          <li>purposes connected with the operation, administration, development or enhancement of this website.
          </li>
          <li>where we suspect that fraud or unlawful activity has been, is being or may be engaged in.
          </li>
          <li>any other purposes required or authorised by law.
          </li>
        </ul>
      <h3>DISCLAIMER</h3>
      <p>This website provides general information, not technical advice. The use of this website (including sending any communication to ABP through it) or any of its contents does not create a client-practitioner relationship. You must not rely upon any of the contents without obtaining corresponding professional psychological advice from ABP.</p>
      <p>To the extent permitted by law, ABP makes no warranty or representation about the accuracy, completeness or fitness for any purpose of the contents of this website, or that this website is free of viruses or other code that is harmful or may assist in causing harm.</p>
      <p>To the extent permitted by law, any condition or warranty regarding this website or its contents that would otherwise be implied (by statute or otherwise) into these terms and conditions is excluded.</p>
      <h3>COMPETITION AND CONSUMER ACT</h3>
      <p>For the purposes of Schedule 2 of the Australian Consumer Law, in particular Sections 51 to 53, 64 and 64A of Part 3-2, Division 1, Subdivision A of the Competition and Consumer Act 2010 (Cth), ABP’s liability for any breach of a term of this agreement is limited to: the supplying of the goods or services to you again; the replacement of the goods; or the payment of the cost of having the goods or services supplied to you again.
      </p>
      <p>You must be over 18 years of age to use this website and to purchase any goods or services.
      </p>
      <h3>RESTRICTIONS ON USE</h3>
      <p>You must only use this website for lawful purposes. You agree not to use the ABP website for illegal purposes, and not to: </p>
      <ul>
        <li>Harass or mistreat other users of our Site.</li>
        <li>Violate the rights of  other users of our Site.
        </li>
        <li>Violate the intellectual property rights of the Site owners or any third party to the Site. </li>
        <li>Hack into the account of another user of the Site. </li>
        <li>Act in any way that could be considered fraudulent.</li>
        <li>Post any material that may be deemed inappropriate or offensive.</li>
      </ul>
      <p>If ABP believes you are using this Site illegally or in a manner that violates these Terms and Conditions, ABP reserves the right to limit, suspend or terminate your access to this Site. ABP also reserves the right to take any legal steps necessary to prevent you from accessing this Site.</p>
      <p>You must take your own precautions to ensure that your access to this website does not expose you to viruses or other code that is harmful to your devices or may assist in causing harm.</p>
      <h3>COPYRIGHT NOTICE</h3>
      <p>Copyright in this website is owned by ABP. Subject to any exceptions that cannot be excluded under any applicable law, and subject to the rights granted to you in this copyright notice, ABP reserves all rights in this website, and you must not in any way reproduce, publish, communicate or adapt any part of this website without the prior written approval of ABP.</p>
      <p>All digital images, including photographs, are protected and may not be downloaded or used for any other purpose.</p>
      <p>You may, for non-commercial purposes:</p>
      <ul>
        <li>browse or print a copy of any part of this website solely to view it; and</li>
        <li>communicate any part of this website to others provided that the relevant part is a self-contained whole (e.g., an entire article), you include an attribution to ABP, and you include these terms and conditions.</li>
      </ul>
      <h3>LIABILITY</h3>
      <p>To the extent permitted by law, ABP excludes all liability to you on any basis (including negligence) for any loss or damage, however caused, which you may suffer in relation to your use of this website or any contents.</p>
      <p>To the extent permitted by law, any liability of ABP in connection with this website under any statutory right (including any condition or warranty implied by law or any guarantee or other right under any statute) that by law cannot be excluded is limited at the option of Alison Budden Psychology to the resupply of the relevant services or the payment of the cost of same.</p>
      <h3>GENERAL</h3>
      <p>A law enforcement agency or government agency may exercise its legal authority to inspect the web server's records when investigating suspected unlawful or improper activity (for example, in relation to hacking or abusive messages).</p>
      <p>If any part of any of these terms and conditions is void, then the part that is void may be severed. Where used in these terms and conditions, the phrase “including” must be read as “including, but not limited to”. Other than any local laws applicable to you that by law cannot be excluded, these terms and conditions are governed by the laws of South Australia, Australia.</p>
      <h3>CONTACT DETAILS</h3>
      <p className='end-page'>Please contact me if you have any questions or concerns - M: 0459 248 358 or E: alison@alisonbuddenpsych.com
      </p>
      </div>
    </>
  )
}

export default Terms;