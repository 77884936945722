import React from 'react';
import Main from './pages/main/main';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Terms from './pages/terms/terms';
import Privacy from './pages/privacy/privacy';
import {
  BrowserRouter as Router,
  // HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/about' element={<About />}/>
        <Route path='/contact' element={<Contact />}/>
        <Route path='/terms' element={<Terms />}/>
        <Route path='/privacy' element={<Privacy />}/>
        <Route path="/" element={<Main/>}/>
      </Routes>
    </Router>
  );
}

export default App;
