import './quote.scss'

const Quote = ({ content, author, image, className, autoSlide }) => {
  return(
    <div className={`${!autoSlide ? 'quote' : undefined} ${className}`}>
      <h2 className='quote-title'>{content}</h2>
      <h4 className='quote-author'>{author}</h4>
    </div>
  )
}

export default Quote;