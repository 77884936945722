import './header.scss';
import '../../style.scss'
import ABP from '../../images/ABP_logo.png'
import { useNavigate } from 'react-router-dom';

const Header = () => {
  let navigate = useNavigate();

  const toHome = () => {
    navigate('/')
  }

  const toAbout = () => {
    navigate('/about')
  }

  const toContact = () => {
    navigate('/contact')
  }

  return (
    <>
    <div className="header-wrapper">
      <div className="flex header">
        <img src={ABP} alt="logo" className="logo" onClick={toHome}/>
        <h1 className="title main-title" onClick={toHome} >
          Alison Budden Psychology
        </h1>
      </div>
      <div className='buttons flex'>
        <p type="button" onClick={toHome} className="button-text">Home</p>
        <p type="button" onClick={toAbout} className="button-text">About</p>
        <p type="button" onClick={toContact} className="button-text">Contact</p>
        <button 
          type="button" 
          className="book-btn">
            <a href='https://clientportal.powerdiary.com/clientportal/alisonbuddenpsychology' target='_blank'>
          Book Online
          </a>
        </button>
      </div> 
    </div>
    </>
  )
}

export default Header;