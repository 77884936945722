import './message.scss'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import Service from '../service/service';

const Message = ({ title, data, readMore }) => {
  const [openReadMore, setOpenReadMore] = useState(false)
  let navigate = useNavigate();

  const dotList = data.dotList;
  const lastMsg = data.lastMsg;
  const readMoreMsg = data.readMoreMsg;

  const setDisplay = () => {
    if (title === "Approach") {
      return(
        <div className='approach-ul'>
        {dotList.map((d, i) => (
          <div className={i < 4 ? "test1":"test2"}><li>{d}</li></div>
        )
        )}
        </div>
      );
        
    } else {
      return (
        <div className='service-container'>
          <Service data={dotList}/>
        </div>
      )
    }
  }

  const checkDotData = () => {
    if (dotList) {
      return (
        <div className='space-bottom message-content'>
          {setDisplay()}
        </div>
      )
    }

    if (lastMsg) {
      return (
        <>
          {lastMsg.map((l, i) => (
            <p key={`last-${i}`} className='message-content'>{l}</p>
          ))}
        </>
      )
    }
  }


  const openExperience = () => {

    if (readMoreMsg) {
      return (
        <>
          {readMoreMsg.map((r, i) => (
            <p key={`readmore-${i}`} className='message-content'>{r}</p>
          ))}
        </>
      )
    }

  }

  const toContact = () => {
    navigate('/contact')
  }
  return (
    <div className='message-container'>
      <h2 className='message-title'>{title}</h2>
      <hr></hr>
      <div className='message'>
        {data.firstMsg.map((f, i) => (
          <p key={`firstMsg-${i}`} className='message-content'>{f}</p>
        ))}
        {checkDotData()}
        {readMore && !openReadMore &&
          <p className='read-more' onClick={() => { setOpenReadMore(true) }}>Read More</p>
        }
        {openReadMore && openExperience()}
        <p>To make a session booking, please <a onClick={toContact} className='to-contact'>Contact</a> or <a href='https://clientportal.powerdiary.com/clientportal/alisonbuddenpsychology' target='_blank'>Book Online</a>.</p>
      </div>

    </div>
  )
}

export default Message;