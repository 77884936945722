import Header from '../../components/header/header';

const Privacy = () => {
  return(
    <>
      <Header />
      <div className='terms-container'>
        <h2>PRIVACY STATEMENT</h2>
        <p>Your right to privacy and confidentiality is very important to me and is reflected in my practice policies. Alison Budden Psychology (ABP) is committed to attaining the highest standards of openness and accountability. The following privacy policy outlines the process of collection and use of your personal information and the way your medical record and personal information is securely and confidentially protected. </p>
        <p>This document also outlines the terms and conditions of use for the ABP website.</p>
        <p>Personal information collected by ABP is treated as confidential and is used only to maintain the integrity of the relevant process. ABP’s information management regime is governed by The Psychology Board of Australia, Australian Health Practitioner Regulation Authority (APHRA) requirements, which has adopted the <a href='https://psychology.org.au/about-us/what-we-do/ethics-and-practice-standards/aps-code-of-ethics' target='_target'>Australian Psychological Society’s Code of Ethics for the profession</a>, International Records Management Standard ISO AS 15489, the Australian Privacy Act 1988, The <i>Privacy Amendment (Enhancing Privacy Protection) Act 2012 and the Mandatory Breach Notification Law and operates in accordance with the</i> <a href='https://www.legislation.gov.au/C2012A00197/latest/versions' target='_target'>Notifiable Data Breaches (NDB) Scheme</a> <i>under the auspices of the</i> <a href='https://www.oaic.gov.au/privacy/notifiable-data-breaches' target='_target'>Office of the Australian Information Commissioner (OAIC)</a>. 
        </p>
        <h3>USE AND DISCLOSURE OF PERSONAL INFORMATION
        </h3>
        <p>The information ABP collects allows me to provide services to you and carry out your requests. As part of providing psychological consultations to you, I will need to collect and record personal information about you that is relevant to your current situation. The information will be a necessary part of psychological assessment and treatment interventions. </p>
        <p>When you enquire about ABP services, your full name, date of birth, email address, phone number, and other personal information, including sensitive information, may be collected to help us assess whether ABP is an appropriate service for you and send you information or resources. ABP collect additional personal information about you, including sensitive information, when you book an appointment and during the course of treatment, for example, your address, emergency contact person, government related identifiers such as Medicare and social and medical history. We may also collect your bank account details and details of third parties involved in the management or funding of your appointments, for the purposes of administering payment arrangements for your appointments.</p>
        <p>Once a client agreement is formed, the information is gathered as part of the assessment, diagnosis and treatment of the client’s condition, and is seen only by the psychologist unless legally required to disclose information or the file is sought for legal or court purposes.</p>
        <p>ABP may collect personal information about you if you are a healthcare professional or healthcare service provider and have referred a client to ABP or are providing services to a client of ABP. Information collected by ABP generally includes your name, medical specialty or position within the organisation you work for, your Medicare provider number, if applicable, and your contact details.</p>
        <p>ABP may collect personal information about you if you interact with ABP on a commercial basis, for example, service providers, or as a member of the public. The kind of information collected will depend on the capacity in which you are dealing with ABP. Generally, it would include your name, your contact details and information regarding our interactions and transactions.</p>
        <p>All information is stored electronically on the Power Diary platform or as an encrypted file on a protected device. Any physical records or files are stored confidentially in locked filing cabinets. Information collected is held for the time periods required under the relevant Australian legislation and regulations.</p>
        <p>All personal information gathered by me during the provision of psychological service will remain confidential and secure except when:</p>
        <ul>
          <li>A court subpoena or other disclosure is required or authorised by law.</li>
          <li>Failure to disclose the information would place you or another person at risk of harm.</li>
          <li>Given your prior approval or consent of a parent or guardian who is legally authorised to act on your behalf to provide a written report to another professional or agency or discuss information with another person, e.g., parent or employer.</li>
          <li>You would reasonably expect your personal information to be disclosed to another professional or agency, and disclosure is directly related to the primary purpose for which it was collected, such as to inform your GP of treatment and progress.</li>
          <li>Clinical consultation with another professional is required to provide better services. If this occurs, identifying details will remain confidential.</li>
        </ul>
        <p>From time to time, it may be necessary for ABP to revise this Privacy Policy. Any changes will be in accordance with any applicable requirements under the Privacy Act and the Australian Privacy Principles. ABP may notify you about changes to this Privacy Policy by posting an updated version on the ABP website. If you require any further information about the Privacy Act and the Australian Privacy Principles, you can visit the Federal Privacy Commissioner’s website (see <a href='https://www.oaic.gov.au/' target='_target'>www.privacy.gov.au</a>)
        </p>
        <p>This Privacy Policy applies to our practice and the ABP site only. ABP assumes no responsibility for the content of any third-party websites. </p>
        <p>Please note that the Power Diary online booking system is a third-party website that is fully compliant with the requirement for health service data collection and protection in Australia. </p>
        <h3>CONTACT DETAILS</h3>
        <p>Please contact me if you have any questions or concerns - M: 0459 248 358 or E: alison@alisonbuddenpsych.com</p>
      </div>
    </>
  )
}

export default Privacy;