const ServicesData ={
  "firstMsg": [
    "I provide treatment for a range of issues, predominantly to adult clients, across each of the adult life stages: early adulthood (18 to 35 years), middle adulthood (35 to 65 years) and late adulthood (65 +).",
    "Issues that present individual challenges and may be areas of focus for therapy:"
  ],
  "dotList": [
    {
      "title": "Growth & Development",
      "img": require('../images/growth.png'),
      "content": [
        "Personal",
        "Professional",
        "Career Decisions"
      ]
    },
    {
      "title": "Attaining + Maintaining",
      "img": require('../images/psych.png'),
      "content": [
        "Psychological Health"
      ]
    },
    {
      "title": "Life Transitions",
      "img": require('../images/transitions.png'),
      "content": [
        "Parenting",
        "Aging well",
        "Relationships",
        "Healthy Adulthood"
      ]
    }
  ]
}

export default ServicesData;