import './slide_show.scss'
import Quote from '../../atoms/quote/quote'
import Quotes from '../../database/quotes.json'
import { useRef, useState, useEffect } from 'react'

const SlideShow = () => {
  const [quoteIndex, setQuoteIndex] = useState(0);
  const delay = 8000;
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if(timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => 
      setQuoteIndex((preQuoteIndex) => preQuoteIndex === Quotes.length -1 ? 0 : preQuoteIndex + 1),  
    delay);
    return () => {
      resetTimeout();
    }
  }, [quoteIndex])

  return(
    <div className='slide-show'>
      <div 
        className='slider'
        style={{ transform: `translate3d(${-quoteIndex * 100}%, 0, 0)` }}
      >
        {Quotes.map((q, i) => (
          <Quote key={i} className='quote-slide' content={q.content} author={q.author} autoSlide={true}/>
        ))}
      </div>
      <div className='dots'>
      <div className='slideShowDots'>
        {Quotes.map((_, index) => (
          <div 
            key={index} 
            className={`slideShowDot ${quoteIndex === index ? "active" : ""}`}
            onClick={() => {
              setQuoteIndex(index)
            }}
          ></div>
        ))}
      </div>
      </div>
    </div>
  )
}

export default SlideShow;