import './user_input.scss'
import { useState, useEffect } from 'react'

const User_Input = ({
  errorMessage, 
  label, 
  onChange, 
  id, 
  sentEmail, 
  ...input}) => {
  const [focused, setFocused] = useState(false)

  const handleBlur = (e) => {
    setFocused(true)
  }

  useEffect(() => {
    if(sentEmail){
      setFocused(false);
    }
  },[sentEmail])

  return(
    <div className='user_input'>
      <div className='flex'>
        <label>{label }</label>
        {input.required && <p className='input-required'>*</p>}
      </div>
      {
        label === 'Message'
        ?  <textarea 
            {...input} 
            onChange={onChange} 
            onBlur={handleBlur} 
            focused={focused.toString()}
            >
          </textarea> 
        : <input 
            {...input} 
            onChange={onChange} 
            onBlur={handleBlur} 
            focused={focused.toString()}
            >
          </input>
      }
      <span className='form-error'>{errorMessage}</span>
    </div>
  )
}

export default User_Input;