import './about.scss'
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import AboutData from '../../database/about.json';
import Quote from '../../atoms/quote/quote';
import ExperienceData from '../../database/experience.json';
import Message from '../../components/message/message';
import AboutImage from '../../images/about.JPG';
import Alison from '../../images/Alison.JPG';
import MAAPi from '../../images/MAAPi-logo-red.png';
// import MAAPi from '../../images/MAAPi-logo-white.png';

const About = () => {
  return (
    <div className="background" style={{ backgroundImage: `linear-gradient(rgba(223,218,215,0.7), rgba(223,218,215,0.7)), url(${AboutImage})` }}>
      <div className="background-container">
        <Header />
        <Quote content='People do not come into therapy to change their past but their future.' author='Milton Erickson' className='about-top' autoSlide={false} />
        <div className='about-intro'>
          <div className='flex'>
            <div>
              <h2 className='about-title'>Alison Budden</h2>
              <hr />
              <div className='about-image-body'>
                <div className='about-img-container'>
                  <img
                    className='about-image'
                    src={Alison}
                    alt='Alison Budden'
                  />
                  <img className='maapi-logo' src={MAAPi} alt='MAAPi logo'/>
                </div>
                <div className='about-content'>
                  <h4></h4>
                  <div className='about-content sub'>
                    {AboutData.map((d, i) => (
                      <p key={i} className='about-body'>{d}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Quote content='Coaching turns problems into challenges, challenges into opportunities and opportunities into gifts.' author='Milton Erickson' className='about-bottom' autoSlide={false} />
        <Message title="Alison's Previous Experience" data={ExperienceData} readMore={true} />
        <Footer />
      </div>
    </div>
  )
}

export default About;